@import '../../styles/variables';
@import '../../styles/mixins';

.package {
  background-color: $color-feed-yellow;
  position: relative;
  margin-bottom: $space-3;
  @include fullWidthPseudoElement();

  @include breakpoint('lg') {
    margin-bottom: $space-3b;
  }
}

:global(.sponsorship-takeover),
:global(.standard-page) {
  // Prevent full width background when there is a takeover
  .package {
    &:before {
      content: none;
    }
  }
}

.content {
  padding: $space-3 $space-2 $space-05;
  margin: 0 auto;
  .title {
    font-size: $text-3b;
    font-family: $feeds-heading-font;
    margin-bottom: 20px;
    align-items: start;
  }
  @include breakpoint('md') {
    padding: $space-3 $space-2 $space-2;
    max-width: calc(1014px + ($space-2 * 2));
  }
  @include breakpoint('lg') {
    display: grid;
    align-items: flex-end;
    padding: 2rem $space-2 39px;
    grid-template-columns: 3fr 1fr;
    .postsWrap {
      grid-area: 2 / 1 / 3 / 3;
    }
    .title {
      font-size: 48px;
      margin-bottom: $space-4;
    }
  }
}
.postsWrap {
  display: grid;
  gap: 16px 0;
  @include breakpoint('sm') {
    grid-template-columns: 0.84fr 1.16fr;
    gap: 24px;
    .topicPosts {
      grid-area: 2 / 1 / 2 / 3;
    }
  }
  @include breakpoint('lg') {
    grid-template-columns: 1.34fr 0.88fr 0.64fr;
    grid-template-rows: 1fr;
    gap: 0 24px;
    align-items: start;
    width: 100%;
    .topicPosts {
      grid-area: 1/3/3/3;
    }
  }
}
.trendingPost {
  margin: 0 0 20px;
  .trendingPosts & {
    &:last-child {
      margin: 0;
    }
  }

  > a {
    display: flex;
    align-items: start;
    &:hover {
      h3 {
        color: $color-feed-purple;
      }
    }
  }
  .thumbnail {
    flex: 1 1;
    max-width: 132px;
    margin: 0 $space-1a 0 0;
  }
  h3 {
    @include truncate(5);
    font-size: $text-6;
    font-weight: $bold;
    font-family: $base-font;
    color: $color-black;
    margin: 0;
  }
  @include breakpoint('sm') {
    .thumbnail {
      width: 51%;
      max-width: 193px;
    }
    h3 {
      font-size: $text-5a;
    }
  }
  @include breakpoint('lg') {
    > a {
      display: block;
    }
    .thumbnail {
      width: 100%;
      max-width: 100%;
      margin-bottom: $space-1a;
    }
  }
}
.thumbnail {
  margin-bottom: $space-1a;
  border-radius: 8px;
  display: block;
}

.topicPost {
  border-top: 1px solid $color-feed-gray-5;
  padding-top: $space-1a;

  &:first-of-type {
    border-top: none;
    padding-top: 0;
  }

  h3 {
    font-size: $text-6;
    font-weight: $bold;
    font-family: $base-font;
    color: $color-black;
    margin: 0 0 $space-1a;
  }
  &:hover {
    h3 {
      color: $color-feed-purple;
    }
  }
  .topicTitle {
    display: block;
    margin-bottom: $space-05;
    text-transform: uppercase;
    font-family: $feeds-heading-font;
    font-size: $text-7;
    color: $color-feed-pink;
  }
  @include breakpoint('sm') {
    h3 {
      font-size: $text-5a;
    }
  }
  @include breakpoint('lg') {
    margin-bottom: $space-2;
    h3 {
      margin-bottom: $space-2;
    }
    .topicPosts & {
      &:last-child {
        border-bottom: none;
      }
    }
    .topicTitle {
      margin-bottom: $space-1;
    }
  }
}

.cta {
  border-top: 1px solid $color-feed-gray-1;
  padding-top: $space-1a;
  font-weight: $bold;
  color: $color-feed-purple;
  display: flex;
  align-items: center;
  svg {
    min-width: 16px;
  }
  @include breakpoint('lg') {
    margin-bottom: 2.5rem;
    margin-left: $space-1;
    text-align: right;
    justify-content: flex-end;
    svg {
      margin-bottom: 1px;
    }
  }
}
a.cta:hover {
  color: $color-feed-pink;
  svg path {
    stroke: $color-feed-pink;
  }
}
