@import 'theming';

// *****************
// * BREAKPOINTS
// *****************
$solid-xs: 0;
$solid-sm: 40rem; //640px
$solid-md: 52rem; //832px
$solid-lg: 64rem; //1024px
$solid-xl: 76rem; //1216px

// *****************
// * FONT
// *****************
$bold: 700;
$xbold: 800;

// *****************
// * COLORS
// *****************
$color-black: #222;
$color-blue: #2070D0;
$color-gray-1: #f4f4f4;
$color-gray-2: #e6e6e6;
$color-gray-7: #757575;
$color-white: #fff;
$color-yellow: #ffee00;

// community colors
$color-bg: #CACCEF;
$color-silver: #C6C8EE;
$color-light-silver: #E8EBFC;
$color-bf-yellow: #FFEC35;
$color-light-yellow: #FFFA97;
$color-facebook-light: #1877F2;
$color-border: #C6C9ED;
$color-hover-google-color:  #EEEEEE;
$color-link:#1A3E81;

// alias
$color-link-blue-hover: #0f65ef;
$yellow: $color-yellow;

// brands
$buzzfeed-red: #dd3324;
$tasty-blue: #75d5ea;
$color-tasty-feed-item-label: #0c8097;
$shopping-peach: #ffe3d4;

$color-facebook: #3b5998;
$color-pinterest: #e60019;
$color-twitter: #55acee;
$color-whatsapp: #43d854;

// feeds
$color-aqua: #b1ecfd;
$color-feed-yellow: #f0fa81;
$color-feed-light-yellow: #fcffdc;
$color-feed-purple: #5246f5;
$color-feed-pink: #eb5369;
$color-light-violet: #cdc9ff;
$color-feed-gray-1: #e0e0e0;
$color-feed-gray-2: #666;
$color-feed-gray-3: #757575;
$color-feed-gray-4: #aaaaaa;
$color-feed-gray-5: #0000001f;

// arcade colors
$cyan-main: #B3FBFF;
$cyan-light: #EEFFFF;
$color-arcade-link-gray: #444;
$color-arcade-link-purple: #481F6B;
$color-arcade-header-pink: #FF71FF;

// Spacing
// -------------------------
$space-05: .25rem !default; // 4px
$space-1: .5rem !default; // 8px
$space-1a: .75rem !default; // 12px
$space-2: 1rem !default; // 16px
$space-3: 1.5rem !default; // 24px
$space-3a: 1.375rem !default; // 22px
$space-3b: 1.75rem !default; // 28px
$space-4: 2rem !default; // 32px
$space-5: 3rem !default; // 48px
$space-6: 4.5rem !default; // 72px

// Typography Sizes and Weights
// ------------------------------
$base-font-size: 16px !default;
$feeds-heading-font:  Calistoga, cursive !default;
$base-font: "Proxima Nova", Helvetica, Arial, sans-serif;

$text-0: 3.5rem; // 56px
$text-05: 3rem; // 48px
$text-05a: 2.875rem; // 46px
$text-1: 2.5rem !default; // 40px
$text-2: 2rem !default; // 32px
$text-3: 1.625rem !default; // 26px
$text-3a: 1.75rem !default; // 28px
$text-3b: 1.5rem !default; // 24px
$text-4: 1.375rem !default; // 22px
$text-4a: 1.25rem !default; // 20px
$text-4b: 1.3rem !default; // 21px ?
$text-5: 1.125rem !default; // 18px
$text-5a: 1rem !default; // 16px
$text-6a: 0.925rem; // 15px
$text-6: 0.875rem; // 14px
$text-7: .75rem !default; // 12px
$text-7a: .625rem !default; // 10px

$lineheight-small: 1rem;
$lineheight-base: 1.2rem;
$lineheight-large: 1.5rem;

// viewport
$content-max-width: calc(1240px + ($space-2 * 2));
$breakpoints: (
  xs: null,
  sm: 40rem, //640px
  md: 52rem, //832px
  lg: 64rem, //1024px
  xl: 76rem, //1216px
);
