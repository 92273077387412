
@import 'bf-solid/_lib/solid-helpers';
@import '../../styles/variables';

.container {
  margin-top: var(--bfpContentMarginTop, 0px);
}

.reactions {
  margin-top: $space-1a;

  /**
   * The parent container has overflow-hidden. Forcing the alignment to the left to prevent the
   * dialog from being cut off.
   */
  dialog {
    left: 0;
    transform-origin: var(--left-offset, 0px) var(--transform-origin-y, 150%);
  }
}

.faceoff_bracket {
  .container {
    /**
     * Applying a border will create a separation between the parent and the child element,
     * preventing their margins from collapsing. This will help in applying consistent margins to
     * the parent container.
     */
    border: 1px solid transparent;
    // -2px to account for the border
    margin: calc(var(--bfpContentMarginTop, 0px) - 2px - $space-2) 0 -#{$space-2};

    /**
     * @todo
     * Matches mobile min height defined in the BFP. This should help with CLS for now but we may
     * want to consider implementing the CLS API instead if CLS becomes a larger issue.
     */
    min-height: 494px;
  }
}
