@import '../../styles/variables';
@import '../../styles/mixins';

.brand {
  display: flex;
  align-items: center;
  color: $color-gray-7;
  font-size: $text-6;
  margin-bottom: $space-1;
  @include breakpoint('lg') {
    margin-bottom: $space-1a;
  }
}
.name {
  a {
    text-decoration: underline;
    font-weight: $bold;
    color: var(--sponsorTextColor, $color-black);
    transition: color 0.3s ease-in-out;
    &:hover {
      text-decoration: none;
    }
  }
}
.logo {
  display: block;
  width: 16px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: $space-1;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.isPromoted {
  margin-bottom: 20px;
  .name {
    color: var(--sponsorTextColor, $color-gray-7);
    .label {
      opacity: 0.75;
    }
    a {
      color: var(--sponsorTextColor, $color-black);
    }
  }
  @include breakpoint('sm') {
    margin-bottom: 0;
  }
}
