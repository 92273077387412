@import 'bf-solid/_lib/solid-helpers';
@import '../../../styles/variables';
@import '../../../styles/mixins';
.feedArticle {
  margin-bottom: $space-3;
  display: flex;
}
.thumbnailWrapper {
  position: relative;
  flex: 1;
  max-width: 141px;

  > a {
    display: flex;
  }

  @include solid-breakpoint('md') {
    max-width: 209px;
  }
}
.thumbnailWrapper:hover + .textWrapper .heading {
  color: $color-feed-purple;
}
.thumbnail {
  border-radius: $space-1;
}

.textWrapper {
  flex: 1;
  margin-bottom: -7px; // Small visual tweak. Offsets white space caused by the stats wrapper text
  margin-left: 10px;
  @include solid-breakpoint('md') {
    margin-left: $space-3;
  }
}

.heading {
  font-family: $base-font;
  font-size: $text-5;
  font-weight: $bold;
  margin-bottom: $space-1a;
  transition: color 0.15s ease;
  @include truncate(4);
  @include solid-breakpoint('md') {
    font-size: $text-3;
    @include truncate(4);
  }
}

.index {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  min-width: 28px;
  height: 28px;
  background-color: $color-aqua;
  color: $color-feed-purple;
  font-family: $feeds-heading-font;
  font-size: $text-5;
  text-align: center;
  line-height: 28px;
  border-radius: $space-1 0 $space-1 0;
  box-shadow: 1px 1px 2px 0px #0000001F;

  @include solid-breakpoint('md') {
    min-width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 22px;
  }
}

.statsWrapper {
  display: inline-flex;
  justify-content: start;

  a {
    display: flex;
    align-items: center;
  }
}

.statsLink {
  color: $color-feed-gray-3;
  &:hover {
    color: $color-feed-gray-3;
    svg {
      transform: scale3d(1.2, 1.2, 1.2);
    }
  }
}

.statistics {
  font-size: $text-6;
  svg {
    width: 14px;
    height: 14px;
  }
  @include breakpoint('md') {
    font-size: $text-5a;
    svg {
      width: 16px;
      height: auto;
    }
  }
}

@include breakpoint('md', 'max') {
  .reactions {
    margin: 0;
  }
  .statistics {
    padding: 0;
  }
}

.topicPostCard {
  .category {
    color: $color-feed-pink;
  }
}

.infoLabel {
  font-size: $text-7;
  @include breakpoint('sm') {
    font-size: $text-5a;
    time {
      font-size: $text-6;
    }
  }
}

.small {
  &.two_columns {
    .textWrapper {
      margin-left: 10px;
    }
  }
}

.medium {
  .thumbnailWrapper {
    max-width: none;
  }

  .heading {
    font-size: $text-2;
  }

  &.standard_page {
    .heading {
      font-family: $feeds-heading-font;
      font-weight: 400;
    }
  }

  // Styles a single-column layout for a post item, stacking the thumbnail on top of the text
  @mixin stackedPostItemContent {
    flex-direction: column;

    .heading {
      margin-top: $space-1;
    }

    .textWrapper {
      margin-left: 0;
    }

    .infoLabel {
      margin-top: $space-1;
    }
  }

  &.two_columns {
    @include stackedPostItemContent;
  }

  @include breakpoint('sm', 'max') {
    @include stackedPostItemContent;
  }
}
