@import '../../styles/variables';
@import '../../styles/mixins';


.thumbnail {
  width: 100%;
  background-color: $color-gray-1;
  aspect-ratio: var(--thumbnailAspectRatio);
  @include breakpoint('sm') {
    aspect-ratio: var(--thumbnailAspectRatioTablet);
  }
  @include breakpoint('lg') {
    aspect-ratio: var(--thumbnailAspectRatioDesktop);
  }
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: $space-1;
  font-size: $text-7;
  color: $color-feed-gray-3;
  @include breakpoint('md') {
    font-size: $text-5a;
  }
}

.tag {
  display: flex;
  align-items: center;
  color: $color-feed-pink;
  font-weight: $bold;
  margin-right: $space-1;
  text-transform: capitalize;
  svg {
    margin: 0 $space-05 3px 0;
  }
}

.stats {
  display: flex;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    line-height: 100%;
    svg {
      margin-right: 0.5rem;
    }
  }

  li:before {
    content:'•';
    display: inline-block;
    margin: 0 10px 0 $text-6;
    line-height: inherit;

    @include breakpoint('md') {
      margin: 0 $text-6 0 $space-2;
    }
  }
}

.section {
  writing-mode: vertical-rl;
  position: relative;
  text-transform: uppercase;
  font-weight: $bold;
  font-size: $text-7;
  width: 18px;
  color: $color-feed-purple;
  span {
    transform: rotate(180deg);
    position: absolute;
    top: 0;
    right: 6px;
    line-height: 1;
    cursor: default;
  }
  @include breakpoint('md') {
    font-size: $text-5a;
    width: 24px;
  }
}
