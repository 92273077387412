@import '../../styles/variables';
@import '../../styles/mixins';

.feedSocialContainer {
  align-items: start;
  background-color: $color-aqua;
  border-radius: 5px 5px 22px 22px;
  color: $themeTextColor;
  display: grid;
  font-family: $base-font;
  grid-column-gap: 20px;
  grid-row-gap: 0;
  grid-template-areas:
      "header"
      "description"
      "embed"
      "reactions";
  position: relative;
  padding: $space-3 $space-2 $space-4;

  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 32px;
    mask-image: url('../../static/img/waves/wave-size-8-bg.svg');
    background-color: var(--wave-color, $color-feed-purple);
    background-repeat: no-repeat;
    mask-position: 0 100%;
    mask-size: 100% 32px;
    mask-repeat: no-repeat;
  }

  @include breakpoint('sm') {
    grid-template-columns: minmax(auto, 350px) 1fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      "embed header"
      "embed description"
      "embed reactions";

    border-radius: $space-1 22px 22px $space-1;
    margin-right: 8px;
    padding: $space-3 calc($space-2 + 8px) $space-3 $space-2;
    &:after {
      height: 100%;
      width: auto;
      left: auto;
      top: 0;
      bottom: auto;
      right: -8px;
      aspect-ratio : 1 / 1;
      transform: rotate(-90deg);
      mask-size: 100% 32px;
      mask-position: 100% 100%;
      mask-image: url('../../static/img/waves/wave-size-8-bg.svg');
    }
  }

  h2 {
    font-family: $base-font;
    font-weight: 700;
  }

  .title {
    margin: 0 0 $space-1a;
    font-size: $text-5;

    @include breakpoint('sm') {
      font-size: $text-4;
    }
  }

  .reactions {
    grid-area: reactions;
    width: fit-content;
  }

  .header {
    grid-area: header;
  }

  .description {
    grid-area: description;
    margin-bottom: $space-1;
  }

  .embed {
    border-radius: 8px;
    grid-area: embed;
    margin: $space-2 0 $space-2;
    min-width: 100%;
    overflow-y: hidden;

    @include breakpoint('sm') {
      margin: 0;
    }

    :global(.twitter-tweet-rendered),
    :global(.instagram-media-rendered) {
      margin: 0 !important;
    }
  }

  blockquote,
  iframe {
    margin: 0;
  }
}

.reactions {
  > ul {
    > li,
    span[aria-label="React"] {
      color: $themeTextColor;
    }
    svg {
      fill: $themeTextColor;
    }

    > li {
      background-color: $color-aqua;
    }
  }
}

:global(.topic-page),
:global(.standard-page) {
  .feedSocialContainer {
    background-color: $themeColor1;
    border-radius: 8px;
    padding-bottom: $space-3;

    &:after {
      content: none;
    }

    .embed {
      background-color: $themeColor2;
    }
  }

  .reactions {
    > ul > li {
      background-color: $themeColor1;
    }
  }
}
