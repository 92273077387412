@import '../../styles/variables';
@import '../../styles/mixins';

/**
 * Animation settings
 */

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
    animation-timing-function: ease-out;
  }
  20% {
    opacity: 1;
    transform: scale3d(1.2, 1.2, 1.2);
    animation-timing-function: ease-in-out;
  }
  50% {
    transform: scale3d(0.92, 0.92, 0.92);
    animation-timing-function: ease-in-out;
  }
  75% {
    transform: scale3d(1.06, 1.06, 1.06);
    animation-timing-function: ease-in-out;
  }
  100% {
    opacity: 1;
    transform: initial;
    animation-timing-function: ease-in-out;
  }
}

@keyframes bounceOut {
  0% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}

$max-emojis: 8;
$animation-duration: 500;
$animation-bounce-delay-step: round($animation-duration / $max-emojis / 2);

.reactions {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--reactionsListItemSize, $text-4);
  line-height: var(--reactionsListItemSize, $text-4);
  cursor: pointer;

  @include breakpoint('md') {
    height: var(--reactionsListItemSizeMd, $text-3);
    line-height: var(--reactionsListItemSizeMd, $text-3);
  }
}

.list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  width: inherit;
  height: 100%;
}

.listItem {
  line-height: calc(var(--reactionsListItemSize, $text-4) + 2px); // +2px to account for the outline
  font-size: var(--reactionsListItemFontSize, $text-7);
  background-color: var(--reactionsListItemBackgroundColor, #EDEDED);
  border-radius: 50%;
  height: var(--reactionsListItemSize, $text-4);
  width: var(--reactionsListItemSize, $text-4);
  text-align: center;
  outline: 1px solid var(--reactionsListItemBorderColor, #fff);
  outline-offset: -1px;

  &:not(:first-child) {
    margin-left: -3px;
  }

  @include breakpoint('md') {
    line-height: calc(var(--reactionsListItemSizeMd, $text-3) + 2px); // +2px to account for the outline
    height: var(--reactionsListItemSizeMd, $text-3);
    width: var(--reactionsListItemSizeMd, $text-3);
    font-size: var(--reactionsListItemFontSizeMd, $text-5a);
  }
}

.total,
.react {
  color: $color-gray-7;
  font-size: var(--reactionsFontSize, $text-6);
  @include breakpoint('md') {
    font-size: var(--reactionsFontSizeMd, $text-5a);
  }
}

.total {
  margin-left: var(--reactionsStatsSpacing, $space-1);

  @include breakpoint('md') {
    margin-left: var(--reactionsStatsSpacingMd, $space-1a);
  }
}

.react {
  margin-left: $space-1;
}

.dialog {
  border: none;
  border-radius: 100vw;
  height: auto;
  margin: $space-1 calc(0% + var(--left-offset, 0px)) $space-1 calc(0% - var(--left-offset, 0px));
  padding: 0;
  bottom: 100%;
  left: initial;
  right: initial;
  z-index: 100;

  opacity: 0;
  transform-origin: calc(50% + var(--left-offset, 0px)) var(--transform-origin-y, 150%);

  box-shadow:
    0 1px 17px rgba(0, 0, 0, 0.2),
    0 0 6px rgba(0, 0, 0, 0.1);

  ul {
    flex-wrap: nowrap;
    margin: 0 0.75rem;
  }

  li {
    font-size: 1.875rem;
    line-height: 1rem;
    margin: 0;
    padding: 0.5rem 0;
    text-align: center;
  }

  button {
    padding: 0.75rem 0.35rem;
    transition: transform 66ms cubic-bezier(0.33, 0, 0.2, 1);

    &:focus {
      outline: none;
    }

    &:focus-visible,
    &:hover {
      transform: scale3d(1.2, 1.2, 1.2);
    }
  }

  .reactionCount {
    font-size: $text-6;
    line-height: $lineheight-small;
    color: $themeTextColor;
    display: block;
    min-height: 17px;
  }

  &[open] {
    animation: bounceIn #{$animation-duration}ms 1ms forwards;
    transform: initial;

    li {
      animation: bounceIn #{$animation-duration}ms forwards;
      opacity: 0;

      @for $i from 1 through $max-emojis {
        &:nth-of-type(#{$i}) {
          animation-delay: #{1ms + ($animation-bounce-delay-step * ($i - 1))};
        }
      }
    }

    &.animateClose {
      animation: bounceOut #{round($animation-duration / 2)}ms forwards cubic-bezier(0.4,-0.3, 0.6,-0.1);
    }
  }
}

.addNewEmoji {
  display: inline-grid;
  font-size: $text-6;
  align-self: stretch;
  line-height: 0;

  &:not(:first-child) {
    margin-left: var(--reactionsStatsSpacing, $space-1);
  }

  @include breakpoint('md') {
    &:not(:first-child) {
      margin-left: var(--reactionsStatsSpacingMd, $space-1a);
    }
    font-size: $text-5a;
  }

  &:hover {
    svg {
      fill: $color-light-violet;
    }
    span {
      color: $color-light-violet;
    }
  }
}

.userReaction {
  background-color: var(--reactionsUserReaction, $color-light-violet);
}

.openEmojiDialogBtn {
  height: 100%;
  padding: 0;
  display: inline-flex;
  align-items: center;
  transform-origin: 50% 50%;
  will-change: transform, opacity;

  &:not(.clearAnimation) {
    animation: bounceIn #{$animation-duration}ms #{round($animation-duration / 1.5)}ms both;
  }

  &:hover {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  svg {
    height: 69.25%;
    width: auto;
  }

  @include breakpoint('md') {
    svg {
      height: $text-5;
    }
  }
}

.confetti {
  position: absolute;
  right: 0;
  z-index: 2147483647; // max heighest number possible in css
  pointer-events: none;
}

// Used to apply on elements after a CSS animation has completed. If the animation involved 3D
// transforms, removing it will help clear memory by removing the 3D rendering context.
// http://go.bzfd.it//css-performance--manage-layer-count
.clearAnimation {
  animation: none;
}

@media (prefers-reduced-motion) {
  .dialog {
    button {
      &:hover {
        outline: 1px var(--color-primary-line) solid;
        transform: none;
      }
    }

    &[open] {
      animation: fadeIn 65ms forwards ease-in;

      li {
        animation: none;
        opacity: 1;
        text-align: center;
      }

      &.animateClose {
        animation: fadeOut 65ms forwards ease-in;
      }
    }
  }
}
