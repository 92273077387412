@import '../../styles/variables';
@import '../../styles/mixins';

button.button,
a.button{
  color: $color-white;
  font-weight: $bold;
  padding: $space-1 $space-3;
  background: $color-feed-purple;
  line-height: 1;
  border: none;
  border-radius: 29px;
  transition: background-color 0.3s ease-in-out;
  text-align: center;
  &:hover {
    background-color: $color-feed-pink;
  }
  &:disabled {
    background-color: $color-feed-gray-2;
  }
}
