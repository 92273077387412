@import '../../../styles/variables';
@import '../../../styles/mixins';

@keyframes fadeInOut {
  // Keep the first few frames transparent to prevent confetti from initially overlapping the users
  // emoji choice.
  10% { opacity: 0; }
  13% { opacity: 1; }

  65% { opacity: 1; }
  100% { opacity: 0; }
}

.container {
  // The duration of the confetti animation.
  --confetti-duration: 1500ms;
  // The vertical distance is applied to the Y axis (negative is up). It only requires a small value
  // since the cubic-bezier function will amplify it using the vertical base value.
  // cubic-bezier function will amplify it using the vertical base value.
  --confetti-distance-vertical: -6px;
  // Values over 1 in a cubic-bezier function will create an overshoot where the animation will go
  // beyond its target state before settling. This value is used as the 2nd property (y1 coordinate)
  // in a cubic-bezier function to amplify the vertical distance to create the overshoot effect.
  --confetti-cubic-bezier-vertical-base: 12;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  perspective: 300px;
  perspective-origin: 0 -150px;
}

.emojis {
  display: inline-grid;
  list-style: none;
  grid-template-areas: 'emoji';
  padding: 0;
  margin: 0;
  transform-style: preserve-3d;
}

.emoji {
  grid-area: emoji; // All confetti emojis are placed in the same grid area to allow them to overlap
  position: relative;
  transition: transform var(--confetti-duration) var(--confetti-delay) cubic-bezier(0, 1, 1, 1);
  transform: rotateY(var(--confetti-rotate)) translate3D(0px, 0px, 0px);
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  will-change: transform;

  .verticalLayer {
    transition: transform var(--confetti-duration) var(--confetti-delay);
    transition-timing-function: cubic-bezier(0, calc(var(--confetti-cubic-bezier-vertical-base) * var(--confetti-scaling-factor)), 1, 1);
    transform: translate3D(0px, 0px, 0px);
    transform-origin: 50% 50%;
    transform-style: preserve-3d;
    will-change: transform;
  }

  .scaleLayer {
    transform-origin: 50% 50%;
    transform: scale(var(--confetti-scale));
    will-change: transform;
  }

  .opacityLayer {
    opacity: 0;
    will-change: opacity;
  }
}

.animate {
  .emoji {
    transform: rotateY(var(--confetti-rotate)) translate3D(0px, 0px, var(--confetti-distance-horizontal));

    .verticalLayer {
      transform: rotateX(2deg) translate3D(0px, var(--confetti-distance-vertical), 0px);
      transition-delay: var(--confetti-delay);
    }

    .opacityLayer {
      animation: fadeInOut var(--confetti-duration) calc(var(--confetti-delay) * .25) forwards ease-in;
    }
  }
}
