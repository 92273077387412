@import '../../../../styles//variables';
@import '../../../../styles/mixins';

.orderedList {
  counter-reset: item;
  padding-left: 0;
}

.orderedList li {
  display: block;
  padding-left: 30px;
  position: relative;
}
.orderedList li:before { 
  content: counter(item) ". ";
  counter-increment: item;
  width: 2em;
  position:absolute;
  left: 0;
}
