@import '../../styles/variables';
@import '../../styles/mixins';

.pageHeader {
  margin-bottom: $space-3a;
  &:first-child {
    margin-top: -24px;
  }
  .imageContainer {
    width: 100%;
    display: flex;
    margin-bottom: $space-3a;
    picture {
      display: flex;
      flex: 1;
      overflow: hidden;
      position: relative;
    }
    figcaption {
      position: absolute;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      font-size: $text-7;
      color: white;
      padding: $space-1;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .textWrapper {
    margin: 0 $space-2;
    padding-bottom: $space-2;
    border-bottom: 1px solid rgb($color-feed-purple, 0.3);
    color: $color-feed-gray-2;
  }
  .title {
    display: flex;
    font-weight: $xbold;
    font-family: $base-font;
    align-items: center;
    color: $color-black;
    font-size: 2.125rem;
    line-height: 1;
    margin-bottom: $space-1;
    img {
      height: 24px;
      width: auto;
      margin-right: $space-2;
    }
  }
  .description {
    p {
      margin: 0;
    }
    a {
      text-decoration: underline;
      color: $color-feed-gray-2;;
      &:hover {
        text-decoration: none;
      }
    }
  }
  @include breakpoint('md') {
    &:first-child {
      margin-top: 0;
    }
  }
  @include breakpoint('xl') {
    .imageContainer {
      img {
        border-radius: $space-1;
      }
    }
    figcaption {
      border-bottom-right-radius: $space-1;
    }
    .textWrapper {
      max-width: calc(60.8% - $space-2*2);
    }
    .title {
      font-size: $text-05;
      img {
          height: 32px;
      }
    }
  }
}
