/**
 * In case of the following scenarios, default colors (periwinkle palette) will be used:
 * - The page does not have any theme data at all.
 * - The page as theme data, but its variable names do not FULLY match expected set (color1, color2,
 *   textColor).
 */
$themeColor1: var(--themeColor1, #8DAAF8);
$themeColor2: var(--themeColor2, #C6D4FB);
$themeTextColor: var(--themeTextColor, #222222);
$themeDarkModeColor1: var(--themeDarkModeColor1, #2A334B);
$themeDarkModeColor2: var(--themeDarkModeColor2, #222222);
$themeDarkModeTextColor: var(--themeDarkModeTextColor, #FFFFFF);
