@import '../../styles/variables';
@import '../../styles/mixins';

// default container with 4 "waves" background
.container {
  background-color: var(--sponsorBackgroundColor, $color-feed-yellow);
  color: var(--sponsorTextColor, $color-black);
  padding: $space-3 $space-2 32px;
  border-radius: $space-1 $space-1 22px 22px;
  margin-bottom: 8px;
  position: relative;
  z-index: 2;
  > * {
    position: relative;
    z-index: 3;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    height: 32px;
    mask-image: url('../../static/img/waves/wave-size-4-bg.svg');
    background-color: var(--sponsorAccentColor, $color-aqua);
    mask-position: 0 100%;
    mask-size: 100% 32px;
    mask-repeat: no-repeat;
  }
  @include breakpoint('sm') {
    &:not(.scrollableContainer &):not(.notSingleColumn &) {
      margin-right: 8px;
      margin-bottom: 0;
      padding: $space-3 32px $space-3 $space-3;
      border-radius: $space-1 22px 22px $space-1;
      &:after {
        height: 100%;
        width: auto;
        left: auto;
        top: 0;
        bottom: auto;
        right: -8px;
        aspect-ratio : 1 / 1;
        transform: rotate(-90deg);
        mask-size: 100% 32px;
        mask-position: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
  &.waves8 {
    &:after {
      mask-image: url('../../static/img/waves/wave-size-8-bg.svg');
    }
  }
}

.heading {
  font-size: $text-3b;
  margin-bottom: $space-1a;
  @include breakpoint('lg') {
    display: none;
  }
}

h2.title,
h3.title{
  font-family: $base-font;
  font-size: $text-5;
  font-weight: $bold;
  margin-bottom: $space-3;
  a {
    color: var(--sponsorTextColor, $color-black);
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    &:hover {
      color: $color-feed-purple;
    }
  }
  @include breakpoint('lg') {
    font-size: $text-4a;
    margin-bottom: $space-2;
  }
}

.subTitle {
  font-size: $text-5a;
  display: block;
  color: var(--sponsorAccentColor, $color-feed-pink);
  font-family: $feeds-heading-font;
  text-transform: uppercase;
  margin-bottom: 10px;
}

// addDiscussion module
.addDiscussion {
  padding: $space-3 $space-2 $space-4;
  @include breakpoint('lg') {
    &:not(.scrollableContainer &):not(.notSingleColumn &) {
      padding: $space-4 $space-3 $space-4 $space-4;
    }
    padding: $space-4 $space-3 $space-4 $space-4;
    .title {
      margin-bottom: $space-3;
    }
  }
}
.replies {
  font-size: $text-5a;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 12px;
  font-weight: $bold;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  svg {
    path {
      stroke: rgba(0, 0, 0, 0.65);
    }
  }
  &:hover {
    color: rgba(0, 0, 0, 0.65);
    text-decoration: underline;
  }
}

// image and text columns
.twoColumns {
  @include breakpoint('sm') {
    padding: 0;
    &:not(.scrollableContainer &):not(.notSingleColumn &) {
      padding: 0;
      display: flex;
      .textWrapper {
        padding: $space-3 $space-2;
      }
      .imageWrapper {
        border-top-left-radius: $space-1;
        border-bottom-left-radius: $space-1;
        border-top-right-radius: 0;
        overflow: hidden;
        flex: 0 0 39%;
        display: flex;
        a {
          width: 100%;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        &.imageWrapperWide {
          flex: 0 0 53.8%;
        }
      }
    }
  }
}
.imageWrapper {
  border-top-left-radius: $space-1;
  border-top-right-radius: $space-1;
  overflow: hidden;
  img {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: auto;
  }
}
.textWrapper {
  padding: $space-2 $space-2 0;
  position: relative;
  z-index: 3;
  .title {
    font-family: $feeds-heading-font;
    font-weight: normal;
    font-size: $text-5;
  }
  &.withDecor {
    &:before {
      content: "";
      position: absolute;
      top: -15px;
      left: 12px;
      z-index: 1;
      border-bottom: 16px solid $color-feed-yellow;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
    }
    @include breakpoint('lg') {
      &:before {
        left: -16px;
        top: 36px;
        border-right: 16px solid $color-feed-yellow;
        border-left: none;
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
      }
    }
  }
}
.text {
  padding: 0 0 $space-2;
  p {
    margin: 0;
  }
}

.avatar {
  display: flex;
  align-items: center;
  color: $color-black;
  font-size: $text-7;
  font-weight: $xbold;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: $color-feed-purple;
  }
  img {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: $space-1;
  }
}

// article module
.article {
  width: 100%;
  padding: 0 0 40px;
  .textWrapper {
    .title {
      display: none;
    }
    .text {
      padding: 0;
      border-top: none;
      text-transform: capitalize;
      font-size: $text-5;
      font-weight: 600;
      transition: color 0.3s ease-in-out;
      p {
        margin-bottom: 12px;
      }
    }
    &:hover {
      .text {
        color: $color-feed-purple;
      }
    }
  }
  .imagePlaceholder {
    aspect-ratio: 3/2;
  }
  @include breakpoint('sm') {
    &:not(.scrollableContainer &):not(.notSingleColumn &) {
      padding: 0 $space-2 0 0;
      width: inherit;
      .textWrapper {
        padding: $space-3 $space-1 $space-3 $space-2;
        .title {
          display: block;
          margin-bottom: 6px;
        }
        .text {
          font-size: $text-5a;
        }
      }
    }
  }
}

// inOut module
.inOut {
  .textWrapper {
    .title {
      display: block;
      margin-bottom: $space-1;
    }
    &:hover {
      .text {
        color: inherit;
      }
    }
  }
  @include breakpoint('sm') {
    &:not(.scrollableContainer &):not(.notSingleColumn &) {
      .textWrapper {
        padding-right: 0;
      }
    }
  }
}

a.readMore {
  color: $color-feed-purple;
  transition: color 0.3s ease-in-out;
  font-weight: $bold;
  .caretIcon {
    path {
      transition: stroke 0.2s ease-in-out;
      stroke: $color-feed-purple;
    }
  }
  &:hover {
    color: $color-feed-pink;
    .caretIcon {
      path {
        stroke: $color-feed-pink;
      }
    }
  }
}
.list {
  margin-bottom: $space-2;
  font-weight: $bold;
  list-style-position: inside;
  line-height: 1.5;
  padding: 0;
}

// scrollable mobile container
.scrollableContainer {
  display: flex;
  margin: 0 (-$space-2);
  overflow-x: scroll;
  padding: 0 0 $space-1 0;

  .slide {
    flex: 0 0 318px;
    margin-right: $space-2;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    &:first-of-type {
      margin-left: $space-2;
    }
  }

  .article {
    display: flex;
    flex-direction: column;
    .textWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @include breakpoint('lg', 'max') {
    scroll-snap-type: x mandatory;
    scroll-padding-left: $space-2;
    overscroll-behavior-x: contain;

    .slide {
      scroll-snap-stop: always;
      scroll-snap-align: start;
    }
  }
}

.columnContainer {
  &.notSingleColumn {
    .slide {
      margin: 0 0 $space-4 0;
    }
  }

  @include breakpoint('sm') {
    display: grid;
    grid-column-gap: $space-4;
    grid-template-columns: 1fr;
    &.notSingleColumn {
      grid-template-columns: repeat(2, 1fr);
      .slide {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        margin: 0;
        .container {
          width: 100%;
        }
        .addDiscussion {
          display: flex;
          flex-direction: column;
          .title {
            flex-grow: 1;
          }
        }
      }
    }
  }
}

// topComment module
.topComment {
  padding-bottom: $space-4;
  .textWrapper {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: row dense;
    column-gap: 6px;
    padding: 0;
    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: $space-1;
    }
  }
  .subTitle,
  blockquote {
    grid-column: 1 / span 2;
  }
  blockquote {
    padding-top: $space-1a;
    cite {
      display: block;
      text-align: right;
      font-size: $text-6;
      font-weight: $bold;
      font-style: normal;
    }
  }
  .title {
    font-size: $text-5a;
    font-family: $base-font;
    font-weight: $bold;
    margin: 0;
    display: flex;
    align-items: center;
    .wrap {
      @include truncate(4);
    }
  }
  .text {
    padding: $space-2;
    border-radius: 2px 12px 12px 12px;
    background: $color-feed-light-yellow;
    margin-bottom: $space-1;
    p {
      margin: 0;
      @include truncate(3);
    }

  }
  @include breakpoint('sm') {
    &:not(.scrollableContainer &):not(.notSingleColumn &) {
      .textWrapper {
        padding: 0 40px 0 0;
      }
      &:after {
        mask-image: url('../../static/img/waves/wave-size-4-bg.svg');
      }
    }
    .textWrapper {
      column-gap: $space-3;
      padding: 0;
      > img {
        grid-row: 1 / span 3;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .title,
    .subTitle,
    blockquote {
      grid-column: initial;
    }
    blockquote {
      margin-bottom: $space-2;
    }
    .text {
      font-size: $text-5;
    }
    .title {
      .wrap {
        @include truncate(2);
      }
    }
    .subTitle {
      margin-top: $space-3;
    }
  }
}

// voting module
.votingAnswer {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  border: 1px solid $color-feed-gray-1;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.10);
  background-color: white;
  width: 100%;
  min-height: 54px;
  padding: $space-05 3.25rem $space-05 $space-2;
  transition: border-color 0.3s ease-in-out;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: var(--votingAnswerValue, 0%);
    height: 4px;
    display: block;
    background-color: $color-feed-gray-1;
    transition: width 0.6s cubic-bezier(0, 0, 0.2, 1) 0.5s;
  }

  &:hover,
  &.active {
    border-color: var(--sponsorAccentColor, $color-feed-purple);
  }
  &:hover {
    cursor: pointer;
    .voteTitle {
      color: var(--sponsorAccentColor, $color-feed-purple);
    }
  }
  @include breakpoint('sm') {
    &:not(.scrollableContainer &):not(.notSingleColumn &) {
      grid-row: 1 / 3;
      justify-content: center;
      min-height: 80px;
      padding: $space-05;
    }
  }
}
.answerTitle {
  text-align: center;
  display: block;
  color: $color-black;
  position: relative;
  z-index: 2;
  transition: color 0.3s ease-in-out;
}
.answerImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
  border-radius: 4px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.answerValue {
  position: absolute;
  top: 50%;
  right: 0;
  text-align: center;
  font-weight: $bold;
  color: $color-feed-gray-2;
  transform: translateY(-50%);
  padding: $space-05 12px $space-05 0;
  z-index: 3;
  opacity: 0;
  @include breakpoint('sm') {
    &:not(.notSingleColumn &) {
      top: 100%;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      padding: $space-1;
    }
  }
}
.votingAnswers {
  display: grid;
  grid-row-gap: 12px;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: opacity 250ms;

  &.loading li {
    opacity: .65;
  }

  li {
    position: relative;
    &.active {
      .votingAnswer {
        border-color: var(--sponsorAccentColor, $color-feed-purple);
        box-shadow: 0 0 0 1px var(--sponsorAccentColor, $color-feed-purple);;

        &:before {
          background-color: var(--sponsorAccentColor, $color-feed-purple);;
        }
      }
      .answerTitle {
        color: var(--sponsorAccentColor, $color-feed-purple);;
        font-weight: $bold;
        flex: 1;
      }
      .answerValue {
        color: var(--sponsorAccentColor, $color-feed-purple);;
      }
    }
  }
  &.isVoted {
    .votingAnswer {
      cursor: default;
      border-color: $color-feed-gray-1;
    }
    .answerTitle {
      color: $color-feed-gray-2;
    }
    .answerValue {
      opacity: 1;
    }
  }
  @include breakpoint('sm') {
    &:not(.notSingleColumn &) {
      margin-bottom: $space-2;
      grid-column-gap: $space-2;
      grid-template-columns: 1fr 1fr 1fr;
      &:has(> :nth-child(2):last-child) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

.headerWrap {
  @include breakpoint('sm') {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 10px;
    .subTitle {
      flex-grow: 1;
      margin-bottom: 0;
    }
  }
}
