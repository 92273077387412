@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.link {
  color: $color-link;
  font-weight: $bold;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    opacity: .8;
  }
}
