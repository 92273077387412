@import '../../../styles/variables';
@import '../../../styles/mixins';

.splashCard {
  align-items: start;
  position: relative;

  .heading {
    font-family: $base-font;
    font-size: $text-5;
    font-weight: $bold;
  }

  @include breakpoint('sm') {
    --reactionsStatsSpacing: #{$space-1a};

    display: block;
  }

  .stats {
    display: inline-flex;
    li:has(.statsLink):before {
      color: $color-feed-gray-3;
    }
  }

  .statsLink {
    display: flex;
    align-items: center;
    color: $color-feed-gray-3;
    &:hover {
      color: $color-feed-gray-3;
      svg {
        transform: scale3d(1.2, 1.2, 1.2);
      }
    }
  }

  .statsWrapper {
    display: inline-flex;
    justify-content: start;
  }
}
.thumbnail {
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    display: block;
  }

  @include breakpoint('sm') {
    &:after {
      content: "";
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 81.77%);
      width: 100%;
      height: 80%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}

.reactions {
  dialog {
    bottom: 100%;
    position: revert;
  }
}

.index {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  min-width: 28px;
  height: 28px;
  background-color: $color-aqua;
  color: $color-feed-purple;
  font-family: $feeds-heading-font;
  font-size: $text-4;
  text-align: center;
  display: grid;
  align-content: center;
  border-radius: $space-1 0;

  @include breakpoint('sm') {
    background-color: unset;
    font-size: $text-05;
    color: $color-light-violet;
    line-height: $text-05;
    height: auto;
    width: $space-5;
    padding: 0 $space-1;
    top: auto;
  }
}

.tagLabel {
  margin: 0 0 $space-1;
  font-size: $text-6;
  span {
    color: $color-light-violet;
    margin: 0;
    svg {
      margin: 0 $space-1 2px 0;
      path {
        fill: $color-light-violet;
        stroke: $color-light-violet;
      }
    }
  }
  @include breakpoint('lg') {
    font-size: $text-5;
  }
}
.linkWrap {
  position: relative;
  display: block;

  @include breakpoint('sm') {
    &:hover {
      .heading,
      .tagLabel,
      .index,
      .statistics {
        color: $color-feed-yellow;
      }
      .statistics {
        opacity: 1;
        svg {
          fill: $color-feed-yellow;
          stroke: $color-feed-yellow;
          path {
            fill: $color-feed-yellow;
          }
        }
      }
      .tagLabel {
        span {
          color: $color-feed-yellow;
          svg {
            path {
              fill: $color-feed-yellow;
              stroke: $color-feed-yellow;
            }
          }
        }
      }
    }
  }
}
.primaryPost {
  --reactionsFontSize: #{$text-5a}; // 16px
  --reactionsListItemFontSize: 15px;
  --reactionsListItemSize: #{$text-3}; // 26px
  --reactionsStatsSpacing: #{$space-1a}; // 12px

  .heading {
    font-size: $text-4;
    margin-bottom: $space-1a;
  }

  .index {
    min-width: 48px;
    height: 48px;
    font-size: $text-1;
  }

  @include breakpoint('sm') {
    .textWrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: $space-2;
    }
    .heading {
      font-family: $feeds-heading-font;
      font-size: $text-4;
      line-height: 120%;
      margin: 0;
      @include truncate(4);
    }
    .index {
      font-size: $text-05;
    }
    // These styles are only applied when the card has an index number provided for rendering over
    // the post item.
    &.hasIndex {
      .linkWrap {
        .heading {
          color: $color-light-violet;
        }
        &:hover {
          .heading {
            color: $color-feed-yellow;
          }
        }
      }
      .statsWrapper {
        left: $space-5;
      }
      .textWrapper {
        padding-left: $space-5;
      }

    }
    &.primaryCard {
      .heading {
        font-size: $text-3a;
      }
    }
    &.hasReactions {
      .textWrapper {
        padding-bottom: $space-5;
      }
    }
    .statsWrapper {
      position: absolute;
      bottom: $space-1a;
      left: $space-2;
    }
    .stats {
      li:has(.statsLink):before {
        color: $color-feed-gray-4;
      }
    }
    .statsLink {
      color: $color-feed-gray-4;
    }
    .statsLink:hover {
      color: $color-light-violet;
    }
    .statsLink svg path {
      stroke: $color-feed-gray-4;
    }
    .statsLink:hover svg path {
      stroke: $color-light-violet;
    }

    .statsLink:hover {
      color: $color-light-violet;
    }
    .reactions {
      ul {
        span {
          color: $color-feed-gray-4;
        }
        span:hover {
          color: $color-light-violet;
        }
        svg {
          fill: $color-feed-gray-4;
        }
        svg:hover {
          fill: $color-light-violet;
        }
      }
      dialog {
        span {
          color: $themeTextColor;
        }
      }
      // variables for the list item
      --reactionsListItemBackgroundColor: #{$color-black};
      --reactionsListItemBorderColor: #{$color-feed-gray-3};
      --reactionsUserReaction: #{$color-feed-gray-3};
    }
  }

  @include breakpoint('md') {
    --reactionsListItemFontSizeMd: #{$text-6};
  }
}
.secondaryPost {
  .heading {
    font-size: $text-5;
    font-family: $base-font;
    font-weight: $bold;
    color: $color-black;
    margin: 0 0 $space-1;
  }
  .textWrapper {
    padding-top: $space-1a;
  }
  .thumbnail:after {
    height: 55%;
  }
  .index {
    bottom: 100%;
    margin-bottom: $space-1;
  }
  .statistics {
    position: absolute;
    bottom: 100%;
    right: 0;
    color: $color-light-violet;
    font-size: $text-5a;
    padding: $space-2 $space-1a;
    li {
      margin: 0 0 0 $space-1a;
    }
    svg {
      width: 18px;
      height: 18px;
      margin-right: $space-05;
      fill: $color-light-violet;
      stroke: $color-light-violet;
      path {
        fill: $color-light-violet;
      }
    }
  }
  .linkWrap {
    &:hover {
      .heading {
        color: $color-feed-purple;
      }
    }
  }
  @include breakpoint('sm') {
    --reactionsFontSize: #{$text-5a};
    --reactionsListItemFontSize: #{$text-5a};
    --reactionsListItemSize: #{$text-3};

    // Remove the gradient overlay if no index number is provided to render over it.
    &:not(.hasIndex) {
      .thumbnail {
        &:after {
          content: none;
        }
      }
    }

    .thumbnail:after {
      height: 35%;
    }
    .textWrapper {
      position: relative;
    }
  }
  @include breakpoint('lg') {
    .heading {
      font-size: $text-5a;
    }
    .statistics {
      opacity: 0;
    }
  }
}

.topicSplashCard,
.topicSplashCardNoBanner,
.topicSplashCardSponsored,
.packageSplashCard {
  position: relative;
  margin: -2.25rem 0 $space-3;

  .linkWrap {
    position: relative;
    display: block;
    margin-bottom: $space-2;

    &:hover {
      .heading {
        color: $color-feed-yellow;
      }
      .tagLabel {
        span {
          color: $color-feed-yellow;
          svg {
            path {
              fill: $color-feed-yellow;
              stroke: $color-feed-yellow;
            }
          }
        }
      }
    }
  }

  .thumbnail {
    &:after {
      content: "";
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 81.77%);
      width: 100%;
      height: 80%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &.primaryPost {
    .textWrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: $space-2;
    }
    .heading {
      font-family: $feeds-heading-font;
      @include truncate(4);
    }

    @include breakpoint('sm') {
      .textWrapper {
        padding-bottom: $space-5;
      }
    }
  }

  .heading {
    color: $color-white;
  }
  .tagLabel {
    span {
      color: $color-white;
      svg {
        path {
          fill: $color-white;
          stroke: $color-white;
        }
      }
    }
  }

  @include breakpoint('sm') {
    --sponsoredCardMargin: calc(-1.75rem - 21px) 0 28px;
    margin: var(--sponsoredCardMargin);
  }

  .statsLink {
    @include breakpoint('sm') {
      left: 0;
    }
  }
}

.topicSplashCardSponsored {
  margin-top: -1rem;
  @include breakpoint('sm') {
    margin: var(--sponsoredCardMargin);
  }
}

.packageSplashCard {
  margin: 0;
  border-bottom: 1px solid $color-feed-gray-5;
  .heading {
    font-size: $text-4a;
    color: $color-light-violet;
  }
  @include breakpoint('sm') {
    border-bottom: none;
    &.primaryPost {
      .textWrapper {
        padding-bottom: $space-2;
      }
    }
    .linkWrap {
      margin-bottom: 0;
    }
  }
  @include breakpoint('md') {
    .heading {
      font-size: $text-4;
    }
  }
}

.topicSplashCardNoBanner {
  position: relative;
  margin: $space-3 0 $space-3;

  .heading {
    color: $color-white;
  }
  .tagLabel {
    span {
      color: $color-white;
      svg {
        path {
          fill: $color-white;
          stroke: $color-white;
        }
      }
    }
  }
  @include breakpoint('md') {
    margin: 28px 0 28px;
  }
}

.standard_page {
  .heading {
    font-family: $feeds-heading-font;
    font-weight: 400;
  }

  @include breakpoint('sm') {
    &.primaryPost {
      .heading {
        color: $color-white;
        &:hover {
          color: $color-feed-yellow;
        }
      }
    }
  }
}
