@import "variables";
//
// Mixins
// --------------------------------------------------

// Generate Breakpoint Prefixes
// -------------------------
// this mixin adds breakpoint prefixes
// from the list of breakpoints to the
// beginning of a block of css selectors

// Include Breakpoint Media Queries
// -------------------------
// returns the apropriate media query
// for the given breakpoint name. Can
// optionally provide a max width instead
// by setting the second argument, `type`,
// to "max".
@mixin breakpoint ($breakpoint, $type: "min") {
    @if (map-has-key($breakpoints, $breakpoint) != true) {
      @error "Breakpoint media query mixin requires a valid breakpoint";
    }
    @else if(map-get($breakpoints, $breakpoint)) == null {
      @content;
    }
    @else if ($type == "max") {
      @media (max-width: calc(#{map-get($breakpoints, $breakpoint)} - 1px)) {
        @content;
      }
    }
    @else {
      @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
        @content;
      }
    }
}

@mixin truncate($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines; /* number of lines to show */
  line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin fullWidthPseudoElement($position: before, $overrides: ()) {
  &:#{$position} {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    background: inherit;
    width: 100vw;
    left: 50%;
    top: 0;
    margin-left: -50vw;
    z-index: -1;

    // Apply any overrides provided
    @each $property, $value in $overrides {
      #{$property}: $value;
    }
  }
}
